import { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Building } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';

const InitialState: Building = {
  shortName: '',
  siteID: 0,
  name: '',
  id: 0,
};

type BuildingFormProps = {
  entity?: Building;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<Building>;
};

export const BuildingForm = ({ disabled, entity, onCancel, onSubmit }: BuildingFormProps) => {
  const methods = useForm<Building>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const formSchema: FieldAttributes[] = [
    {
      label: 'Name',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
      required: true,
    },
    {
      label: 'Short Name',
      name: 'shortName',
      type: FieldType.TEXT,
      control: methods.control,
      required: true,
    },
    {
      label: 'Site',
      name: 'siteID',
      type: FieldType.AUTOCOMPLETE,
      control: methods.control,
      required: true,
      dataUrl: '/api/contactGroup/query',
    },
  ];

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: Building) => onSubmit(d)} />;
};
