import { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from './header';
import Sidebar from './sidebar/Sidebar';
import HorizontalBar from '../../components/shared/HorizontalBar';
import MainContentStyled from './Dashboard.styled';
import Loader from 'components/shared/Loader';
import Breadcrumbs from 'components/extended/Breadcrumbs';
import useConfig from 'hooks/useConfig';
import { handlerDrawerOpen, useGetMenuMaster } from 'api/menu';
import { MenuOrientation } from 'types/theme';
import { useVersion } from 'hooks/useVersion';
import { Typography } from '@mui/material';
import useAppContext from 'hooks/useAppContext';
import { ClientTitle } from 'components/shared/ClientTitle';

// ==============================|| MAIN LAYOUT ||============================== //

const DashboardLayout = () => {
  useVersion(); // check version and reload
  const theme = useTheme();
  const { context } = useAppContext();
  const downMD = useMediaQuery(theme.breakpoints.down('md'));

  const { borderRadius, container, miniDrawer, menuOrientation } = useConfig();
  const { menuMaster, menuMasterLoading } = useGetMenuMaster();
  const drawerOpen = menuMaster?.isDashboardDrawerOpened;

  useEffect(() => {
    handlerDrawerOpen(!miniDrawer);
  }, [miniDrawer]);

  useEffect(() => {
    downMD && handlerDrawerOpen(false);
  }, [downMD]);

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downMD;

  // horizontal menu-list bar : drawer
  const menu = useMemo(() => (isHorizontal ? <HorizontalBar /> : <Sidebar />), [isHorizontal]);

  if (menuMasterLoading) return <Loader />;

  return (
    <Box sx={{ display: 'flex' }}>
      {/* header */}
      <AppBar enableColorOnDark position='fixed' color='inherit' elevation={0} sx={{ bgcolor: 'background.default' }}>
        <Toolbar sx={{ p: isHorizontal ? 1.25 : 2 }}>
          <Header />
        </Toolbar>
        {downMD && context && (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ mt: -2 }}>
            <Typography variant='subtitle1'>
              <ClientTitle showSiteCode showBuilding showSystem />
            </Typography>
          </Box>
        )}
      </AppBar>

      {/* menu / drawer */}
      {menu}

      {/* main content */}
      <MainContentStyled {...{ borderRadius, menuOrientation, open: drawerOpen /*, theme*/ }}>
        <Container maxWidth={container ? 'lg' : false} {...(!container && { sx: { px: { xs: 0 } } })}>
          {/* breadcrumb */}
          {/* <Breadcrumbs /> */}
          <Outlet />
        </Container>
      </MainContentStyled>
    </Box>
  );
};

export default DashboardLayout;
