import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { SignalParseType } from 'entities/enums';
import { BeaconVM } from 'entities/viewModels';
import useAppContext from 'hooks/useAppContext';

interface BeaconsTableActions extends DataGridActionProps<BeaconVM> {}

export default function BeaconsTable({ actions }: BeaconsTableActions) {
  const { context } = useAppContext();
  const columns: GridColDef<BeaconVM>[] = [
    {
      field: 'name',
      headerName: 'Name',
      filterable: false,
      width: 200,
    },
    {
      field: 'system.building',
      headerName: 'Building',
      filterable: false,
      width: 200,
      renderCell(params) {
        return params.row.system?.building?.name ?? '';
      },
    },
    {
      field: 'system',
      headerName: 'System(s)',
      filterable: false,
      width: 240,
      renderCell(params) {
        return params.value?.name ? `${params.value?.name} [ID:${params.row.system?.id}]` : '';
      },
    },
    {
      field: 'parser',
      headerName: 'Parser',
      filterable: false,
      width: 100,
      renderCell(params) {
        return SignalParseType[params.value];
      },
    },
    {
      field: 'antiFloodEnabled',
      headerName: 'Anti Flood Signals',
      filterable: false,
      width: 300,
      renderCell(params) {
        return params.value ? `Enabled, ${params.row.antiFloodCount} signals within ${(params.row.antiFloodInterval ?? 0) / 1000} seconds` : 'Not enabled';
      },
    },
  ];

  return (
    <DataGrid
      key={'beaconsTable'}
      dataUrl={`/api/beacon/query/${context?.clientId}`}
      hideHeader={false}
      orderBy={'name asc'}
      columnDefinitions={actions ? [...actions, ...columns] : columns}
    />
  );
}
