import { useEffect, useRef, useState } from 'react';
import { styled, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import Toolbar from 'components/calendar/Toolbar';
import CalendarStyled from 'components/calendar/CalendarStyled';
import Loader from 'components/shared/Loader';
import SubCard from 'components/cards/SubCard';
import { useNavigate } from 'react-router-dom';
import { CalendarItem } from 'entities';
import { EventContentArg, EventInput } from '@fullcalendar/core';
import useAppContext from 'hooks/useAppContext';
import useAxios from 'hooks/useAxios';
import logger from 'utils/logger';

const WorkItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
}));

const Calendar = () => {
  const { get } = useAxios();
  const { context } = useAppContext();
  const calendarRef = useRef<FullCalendar>(null);
  const matchSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const [loading, setLoading] = useState<boolean>(false);

  // fetch events data
  const [events, setEvents] = useState<EventInput[]>([]);

  const renderEventContent = (eventContent: EventContentArg) => {
    return (
      <WorkItem>
        <b>
          {eventContent.timeText} {eventContent.event.extendedProps['area']}:
        </b>
        <i>
          {eventContent.event.extendedProps['workType'] ?? ''} for {eventContent.event.extendedProps['systemsEffected']?.replaceAll('|', ', ') ?? ''} [Order#:{' '}
          {eventContent.event.extendedProps['orderNumber']}]
        </i>
      </WorkItem>
    );
  };

  const renderEvents = (eventData: CalendarItem[]) => {
    if (eventData) {
      logger.log(eventData);
      const groups = eventData.map((d) => d.workType);

      const events = eventData.map((we) => {
        return {
          title: `[${we.workType}] ${we.name}`,
          start: we.startDate,
          end: we.endDate,
          allDay: we.allDay,
          groupId: groups.indexOf(we.workType).toString(),
          extendedProps: we,
        } as EventInput;
      });
      setEvents(events);
    }
  };

  useEffect(() => {
    get<CalendarItem[]>(`/api/calendaritem/${context?.buildingId}/all`).then(renderEvents);
  }, []);

  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(matchSm ? 'listWeek' : 'dayGridMonth');

  // calendar toolbar events
  const handleDateToday = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleViewChange = (newView: string) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  // set calendar view
  useEffect(() => {
    handleViewChange(matchSm ? 'listWeek' : 'dayGridMonth');
  }, [matchSm]);

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  if (loading) return <Loader />;

  return (
    <CalendarStyled>
      <Toolbar date={date} view={view} onClickNext={handleDateNext} onClickPrev={handleDatePrev} onClickToday={handleDateToday} onChangeView={handleViewChange} />
      <SubCard>
        <FullCalendar
          weekends
          editable
          droppable
          selectable
          events={events}
          ref={calendarRef}
          rerenderDelay={10}
          initialDate={date}
          initialView={view}
          dayMaxEventRows={3}
          eventDisplay='block'
          headerToolbar={false}
          allDayMaintainDuration
          eventResizableFromStart
          height={matchSm ? 'auto' : 720}
          //eventContent={renderEventContent}
          plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
        />
      </SubCard>
    </CalendarStyled>
  );
};

export default Calendar;
