import { AddLocation } from '@mui/icons-material';
import { MenuItem, ListItemIcon, ListItemText, Autocomplete, Box, Button, DialogActions, DialogContent, FormControl, TextField, Divider } from '@mui/material';
import { Device } from 'entities';
import { MapItem } from 'entities';
import useAxios from 'hooks/useAxios';
import { LatLng } from 'leaflet';
import { useState, useEffect } from 'react';
import { MenuItemProps } from '../ContextMenu';
import useAppContext from 'hooks/useAppContext';
import { ContextDialog } from '../ContextDialog';
import useMaps from 'hooks/useMaps';

interface AddDeviceProps extends MenuItemProps {
  position?: LatLng;
  disabled?: boolean;
}

export const AddDevice = ({ position, disabled, onClick }: AddDeviceProps) => {
  const { get, post } = useAxios();
  const { context } = useAppContext();
  const { map, refreshMap } = useMaps();

  // dialog props
  const [dialogOpen, setDialogOpen] = useState(false);

  // autocomplete props
  const [value, setValue] = useState('');
  const [device, setDevice] = useState<Device>();
  //   const [position, setPosition] = useState<LatLng>();
  const [devices, setDevices] = useState<Device[]>([]);

  const handleDialogClose = () => {
    setDialogOpen(false);
    onClick();
  };

  //   const handleAddMarker = (position?: LatLng) => {
  //     if (position) {
  //       setPosition(position);
  //       setDialogOpen(true);
  //     }
  //   };

  const handleDialogSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (map && position && device) {
      const mapId = map.id;
      const mapItem: MapItem = { id: 0, lat: position.lat, lng: position.lng, mapID: mapId, name: 'Device item' /*device ? '' : value*/, deviceId: device?.id };
      post(`/api/mapItem/`, mapItem).then(() => {
        refreshMap();
        handleDialogClose();
        setDevices([]);
        setDevice(undefined);
        onClick();
        // setPosition(undefined);
        // setContextMenu(null);
      });
    }
  };

  useEffect(() => {
    if (value.length >= 3) get<Device[]>(`/api/device/query/${context?.systemId}`, { search: value, pageSize: 100, page: 0 }).then(setDevices);
    else setDevices([]);
  }, [value]);

  return (
    <>
      <MenuItem onClick={() => setDialogOpen(true)} disabled={disabled}>
        <ListItemIcon>
          <AddLocation fontSize='small' />
        </ListItemIcon>
        <ListItemText>Add Device</ListItemText>
      </MenuItem>
      <ContextDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSubmit={handleDialogSubmit}
        actions={
          <>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button type='submit' disabled={!device}>
              Save
            </Button>
          </>
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: 'fit-content',
          }}
        >
          <FormControl sx={{ mt: 2, minWidth: 300 }}>
            <Autocomplete
              freeSolo
              autoFocus
              id='device'
              options={devices}
              filterOptions={(x) => x}
              onChange={(_, v) => (v ? setDevice(typeof v !== 'string' ? v : undefined) : setDevice(undefined))}
              getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.address})`)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  autoComplete='off'
                  {...params}
                  // autoFocus
                  required
                  // focused={true}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  label='Search device'
                  InputProps={{
                    ...params.InputProps,
                    // type: 'search',
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </FormControl>
          {device && (
            <Box mt={3} display={'flex'} flexDirection={'column'} gap={3}>
              <Divider />
              <TextField autoComplete='off' disabled label='Name' defaultValue={device?.name} />
              <TextField autoComplete='off' disabled label='Address' defaultValue={device?.address} />
              <TextField autoComplete='off' disabled label='Descrition' multiline={true} minRows={2} defaultValue={device?.description} />
            </Box>
          )}
        </Box>
      </ContextDialog>
    </>
  );
};
