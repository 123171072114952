import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { Controller, ControllerFieldState, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { MultiSelectFieldAttributes } from '.';
import { AxiosResponse } from 'axios';
import logger from '../../utils/logger';
import { Entity } from '../../entities/base/Entity';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useAxios from 'hooks/useAxios';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

//TODO implement https://github.com/mui/material-ui/blob/v5.15.0/docs/data/material/components/autocomplete/CheckboxesTags.tsx
export const MultiSelectField: React.FC<MultiSelectFieldAttributes> = ({ label, name, data, disabled, hidden, required, selectAll, limitTags, description, control, render }) => {
  const { get } = useAxios();
  const { register, setValue, clearErrors } = useFormContext();
  const [options, setOptions] = useState<Entity[]>([]);

  const hasError = (field: ControllerRenderProps<any, string>, state: ControllerFieldState) => {
    console.log('FIELD', field);
    return (state.error?.message ?? '') !== '';
  };

  useEffect(() => {
    if (typeof data === 'string') get<Entity[]>(data).then(setOptions);
    else setOptions(data);
  }, [data]);

  return (
    <section>
      {options && (
        <Controller
          name={name}
          control={control}
          //rules={{ required: required ? 'This field is mandatory' : undefined }}
          disabled={disabled}
          render={({ field, fieldState }) => (
            <Autocomplete
              {...field}
              {...register(name)}
              size='small'
              multiple
              freeSolo
              hidden={hidden}
              options={options}
              //disableCloseOnSelect
              disabled={disabled}
              limitTags={limitTags}
              value={field.value ?? null}
              getOptionKey={(o) => o.id}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {render ? render(option) : option.name}
                </li>
              )}
              style={{ width: '100%', margin: '5px' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  autoComplete='off'
                  required={required}
                  value={field.value}
                  error={!!fieldState.error}
                  onChange={() => clearErrors(name)}
                  helperText={fieldState.error?.message}
                  placeholder={`Select ${label.toLowerCase()}`}
                  // helperText={fieldState.error ? 'This field is mandatory' : (description ?? '')}
                />
              )}
              onChange={(_, v) => {
                console.log('ONCHANGE', v);
                setValue(name, v);
                clearErrors(name);
                return v;
              }}
            />
          )}
        />
      )}
    </section>
  );

  // return (
  //   <section>
  //     <Controller
  //       name={name}
  //       control={control}
  //       rules={{ required: required }}
  //       disabled={disabled}
  //       render={({ field }) =>
  //         field.value && (
  //           <Autocomplete
  //             {...field}
  //             {...register(name)}
  //             multiple
  //             freeSolo
  //             hidden={field.disabled} // TODO fix with hidden prop, works for now
  //             options={options}
  //             disableCloseOnSelect
  //             getOptionLabel={(option) => option.name}
  //             renderOption={(props, option, { selected }) => (
  //               <li {...props}>
  //                 <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
  //                 {renderOptionLabel ? renderOptionLabel(option) : option.name}
  //               </li>
  //             )}
  //             // value={field.value}
  //             //defaultValue={field.value}
  //             style={{ width: '100%', margin: '5px' }}
  //             // onChange={(e, v) => handleChange(e, v)}
  //             renderInput={(params) => (
  //               <TextField autoComplete='off'
  //                 {...params}
  //                 label={label}
  //                 // itemID={params.id}
  //                 // placeholder={
  //                 //   selectItems.length === 0 ? `Select ${label}${selectAll ? ', leave blank for all' : ''}` : ''
  //                 // }
  //               />
  //             )}
  //           />
  //         )
  //       }
  //     />
  //   </section>
  // );
};
