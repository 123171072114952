import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { Shift } from 'entities';

interface ShiftsTableActions extends DataGridActionProps<Shift> {}

export default function ShiftsTable({ actions }: ShiftsTableActions) {
  const columns: GridColDef<Shift>[] = [
    {
      field: 'date',
      headerName: 'Date',
      filterable: false,
      width: 200,
      renderCell(params) {
        return <>{new Date(params.value).toLocaleString()}</>;
      },
    },
    {
      field: 'name',
      headerName: 'Shift',
      filterable: false,
      width: 300,
    },
  ];

  return <DataGrid id={'shiftsTable'} dataUrl='/api/shift/me/query' hideHeader={false} orderBy={'date desc'} columnDefinitions={actions ? [...actions, ...columns] : columns} />;
}
