import React from 'react';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { InputFieldAttributes } from '.';

export const InputField: React.FC<InputFieldAttributes> = ({ label, name, inputType, multiline, disabled, control, required }) => {
  const { register } = useFormContext();

  return (
    <section>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field, fieldState }) => (
          <TextField
            autoComplete='off'
            {...field}
            {...register(name)}
            size='small'
            multiline={multiline}
            rows={multiline ? 6 : 1}
            // maxRows={6}
            style={{ width: '100%', margin: '5px' }}
            type={inputType ?? 'text'}
            label={label}
            value={field.value ?? ''}
            variant='outlined'
            disabled={disabled}
            required={required}
            error={fieldState.error !== undefined}
            helperText={fieldState.error ? 'This field is mandatory' : ''}
          />
        )}
      />
    </section>
  );
};
