import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { PulseRelay } from 'entities';

interface PulseRelaysTableActions extends DataGridActionProps<PulseRelay> {}

export default function PulseRelaysTable({ actions }: PulseRelaysTableActions) {
  const columns: GridColDef<PulseRelay>[] = [
    {
      field: 'name',
      headerName: 'Relay',
      filterable: false,
      width: 250,
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      filterable: false,
      width: 100,
    },
    {
      field: 'contactGroups',
      headerName: 'Contact groups',
      filterable: false,
      width: 200,
      renderCell(params) {
        return params.row.contactGroups?.map((g) => g.name).join(', ') ?? '';
      },
    },
    {
      field: 'buildings',
      headerName: 'Buildings',
      filterable: false,
      width: 200,
      renderCell(params) {
        return params.row.buildings?.map((g) => g.name).join(', ') ?? '';
      },
    },
    {
      field: 'signalTypes',
      headerName: 'Signal types',
      filterable: false,
      width: 450,
      renderCell(params) {
        return params.row.signalTypes?.map((g) => g.name).join(', ') ?? '';
      },
    },
  ];

  return (
    <Box component='div' sx={{ width: '100%', height: '100%' }}>
      <DataGrid id={'pulseRelaysTable'} dataUrl='/api/pulseRelay/query' columnDefinitions={actions ? [...actions, ...columns] : columns} />
    </Box>
  );
}
