import { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { CalendarItem } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';

const InitialState: CalendarItem = {
  startDate: '',
  endDate: '',
  allDay: false,
  workType: '',
  area: '',
  systemsEffected: '',
  requiredFireWatch: false,
  requiredSystemImpairment: false,
  contractor: '',
  orderNumber: '',
  name: '',
  id: 0,
  buildingId: 0,
};

type CalendarFormProps = {
  entity?: CalendarItem;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<CalendarItem>;
};

export const CalendarForm = ({ disabled, entity, onCancel, onSubmit }: CalendarFormProps) => {
  const methods = useForm<CalendarItem>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const formSchema: FieldAttributes[] = [
    {
      label: 'Title',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
      required: true,
    },
    {
      label: 'Work type',
      name: 'workType',
      type: FieldType.TEXT,
      control: methods.control,
      required: true,
    },
    {
      label: 'Start date',
      name: 'startDate',
      type: FieldType.DATE,
      control: methods.control,
      required: true,
      span: 2.4,
    },
    {
      label: 'End date',
      name: 'endDate',
      type: FieldType.DATE,
      control: methods.control,
      required: true,
      span: 2.4,
    },
    {
      label: 'All day',
      name: 'allDay',
      type: FieldType.CHECKBOX,
      control: methods.control,
      span: 1.2,
    },
    {
      label: 'Fire Watch on-site',
      name: 'requiredFireWatch',
      type: FieldType.RADIO,
      control: methods.control,
      span: 3,
    },
    {
      label: 'System impairment',
      name: 'requiredSystemImpairment',
      type: FieldType.RADIO,
      control: methods.control,
      span: 3,
    },
    {
      label: 'Area',
      name: 'area',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Systems effected',
      name: 'systemsEffected',
      type: FieldType.TEXT,
      control: methods.control,
    },

    {
      label: 'Contactor',
      name: 'contractor',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Order number',
      name: 'orderNumber',
      type: FieldType.TEXT,
      control: methods.control,
    },
  ];

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: CalendarItem) => onSubmit(d)} />;
};
