import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { System } from 'entities';
import { IntervalType } from 'entities/enums/IntervalType';
import { InspectionTypeVM } from 'entities/viewModels';
import useAppContext from 'hooks/useAppContext';
import { ReactNode } from 'react';

interface InspectionTypesTableActions extends DataGridActionProps<InspectionTypeVM> {}

export default function InspectionTypesTable({ actions }: InspectionTypesTableActions) {
  const { context } = useAppContext();

  const columns: GridColDef<InspectionTypeVM>[] = [
    { field: 'name', headerName: 'Name', width: 400, filterable: false },
    {
      field: 'groups',
      headerName: 'System(s)',
      width: 400,
      filterable: false,
      renderCell(params): ReactNode {
        const systems = params.row.systems as System[];
        return <>{systems?.map((g) => g.name).join(', ') ?? '-'}</>;
      },
    },
    {
      field: 'interval',
      headerName: 'Interval',
      width: 200,
      filterable: false,
      renderCell(params): ReactNode {
        return <>{params.row.intervalValue && params.row.intervalType ? `every ${params.row.intervalValue} ${IntervalType[params.row.intervalType]}` : 'N/A'}</>;
      },
    },
  ];

  return <DataGrid id={'inspectionTypesTable'} dataUrl={`/api/inspectionType/query/${context?.systemId}`} columnDefinitions={actions ? [...actions, ...columns] : columns} />;
}
