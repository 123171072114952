import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxFieldAttributes, RadioFieldAttributes } from '.';
import { Checkbox } from '@mui/material';

export const CheckboxField: React.FC<CheckboxFieldAttributes> = ({ label, name, type, control, required }) => {
  const { register } = useFormContext();

  return (
    <section>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field }) => (
          <FormControl {...field} {...register(name)} size='small'>
            <FormControlLabel control={<Checkbox />} label={label} />
          </FormControl>
        )}
      />
    </section>
  );
};
