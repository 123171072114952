import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { AutoCompleteFieldAttributes } from '.';

import logger from '../../utils/logger';
import { AxiosResponse } from 'axios';
import useAxios from 'hooks/useAxios';

export const AutoCompleteField: React.FC<AutoCompleteFieldAttributes> = ({ label, name, dataUrl, control, disabled, required }) => {
  const { get } = useAxios();
  const { register, setValue, clearErrors } = useFormContext();
  const [options, setOptions] = useState<string[]>([]);

  // const renderOptions = (response: AxiosResponse) => {
  //   logger.log('SELECT [%s] data:', label.toUpperCase(), response.data);
  //   setOptions(response.data);
  // };

  useEffect(() => {
    get<string[]>(dataUrl).then(setOptions);
  }, [dataUrl]);

  return (
    <div>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field, fieldState }) => (
          <>
            <Autocomplete
              {...field}
              {...register(name)}
              size='small'
              freeSolo
              options={options}
              disabled={disabled}
              value={field.value ?? null}
              style={{ width: '100%', margin: '5px' }}
              renderInput={(params) => (
                <TextField
                  required={required}
                  error={fieldState.error !== undefined}
                  helperText={fieldState.error ? 'This field is mandatory' : ''}
                  autoComplete='off'
                  {...params}
                  label={label}
                />
              )}
              onChange={(_, v) => {
                setValue(name, v);
                clearErrors(name);
                return v;
              }}
            />
            {fieldState.error && <p>{fieldState.error.message}</p>}
          </>
        )}
      />
    </div>
  );
};
