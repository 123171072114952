import { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Beacon } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';

const InitialState: Beacon = {
  name: '',
  id: 0,
};

type BeaconFormProps = {
  entity?: Beacon;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<Beacon>;
};

export const BeaconForm = ({ disabled, entity, onCancel, onSubmit }: BeaconFormProps) => {
  const methods = useForm<Beacon>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const formSchema: FieldAttributes[] = [
    {
      label: 'Name',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
      required: true,
    },
  ];

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: Beacon) => onSubmit(d)} />;
};
