import { Button, Grid2 as Grid, Box } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLoaderData } from 'react-router-dom';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { FieldAttributes, Field, FieldType } from '../components/fields';
import { Inspection } from '../entities/Inspection';
import useAxios from 'hooks/useAxios';
import { NamedEntity } from 'entities/base/Entity';
import useAppContext from 'hooks/useAppContext';
import { LoadingButton } from '@mui/lab';

export type FormProps = {
  returnUrl?: string;
  readOnly?: boolean;
  entity?: NamedEntity;
};

const InitialState: Inspection = {
  startDate: '',
  typeID: 0,
  name: '',
  id: 0,
  systemID: 0,
};

export const InspectionForm = ({ readOnly, entity, returnUrl }: FormProps) => {
  const { context } = useAppContext();
  const { get, put, post } = useAxios();
  const navigate = useNavigate();
  const params = useLoaderData() as string;

  const [id, setId] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<Inspection>(entity as Inspection);

  const methods = useForm<Inspection>({
    defaultValues: useMemo(() => {
      return state ?? { ...InitialState, buildingID: context?.buildingId };
    }, [state]),
  });

  const onSubmit: SubmitHandler<Inspection> = (data: Inspection) => {
    const id = data.id;
    data.startDate = new Date(data.startDate).toISOString();
    if (id && id > 0) {
      //TODO implement formatter
      if (data.typeID !== data.type?.id) data.type = undefined;
      put(`/api/inspection/${id}`, data).then(() => navigate('/inspections'));
    } else {
      // console.log('POST INSPECTION', data);
      post(`/api/inspection`, data).then(() => navigate('/inspections'));
    }
  };

  const formSchema: FieldAttributes[] = [
    {
      label: 'Name',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Type',
      name: 'typeID',
      type: FieldType.LOOKUP,
      dataUrl: `/api/inspectionType/query/${context?.systemId}`,
      control: methods.control,
    },
    {
      label: 'Start Date',
      name: 'startDate',
      type: FieldType.DATE,
      control: methods.control,
      format: 'LL',
    },
    {
      label: 'End Date',
      name: 'endDate',
      type: FieldType.DATE,
      control: methods.control,
      format: 'LL',
      disabled: true,
    },
  ];

  useEffect(() => {
    methods.reset(state);
  }, [state]);

  useEffect(() => {
    if (id && id > 0) {
      get<Inspection>(`/api/inspection/${id}`).then(setState);
    } else methods.reset(InitialState);
  }, [id]);

  useEffect(() => {
    methods.reset(InitialState);
    if (!isNaN(parseInt(params))) setId(parseInt(params));
  }, [params]);

  return (
    <FormProvider {...methods}>
      <form className='form' onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {formSchema.map((field, key) => (
            <Grid key={key} size={{ xs: 12, sm: 6, md: 6 }}>
              <Field {...field} disabled={readOnly || field.disabled} />
            </Grid>
          ))}
        </Grid>
        {!readOnly && (
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 2 }}>
            <Button type='button' variant='outlined' onClick={() => navigate(returnUrl ?? '/inspections')}>
              Cancel
            </Button>
            <LoadingButton disabled loading={loading} type='submit' variant='contained'>
              Save
            </LoadingButton>
          </Box>
        )}
      </form>
    </FormProvider>
  );
};
