import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { Site } from 'entities';
import useAppContext from 'hooks/useAppContext';

interface SitesTableActions extends DataGridActionProps<Site> {}

interface SitesTableProps extends SitesTableActions {}

export default function SitesTable({ actions }: SitesTableProps) {
  const { context } = useAppContext();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      filterable: false,
      width: 300,
    },
  ];

  return <DataGrid id={'sitesTable'} dataUrl={`/api/site/query/${context?.clientId}`} orderBy={'name asc'} columnDefinitions={actions ? [...actions, ...columns] : columns} />;
}
