// material-ui
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import logoDark from 'assets/images/logo-dark.png';
import logo from 'assets/images/logo.png';

// types
import { ThemeMode } from 'types/theme';
import useAppContext from 'hooks/useAppContext';
import { ClientTitle } from './ClientTitle';

const Logo = () => {
  const theme = useTheme();
  const { context, contact } = useAppContext();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
      <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt='Beacon Suite Logo' height='40' width='auto' />
      <ListItemText
        primary={
          <Typography variant={'h3'} color='inherit'>
            Beacon Suite
          </Typography>
        }
        secondary={
          context &&
          contact && (
            <Typography noWrap={true} variant='caption' sx={{ ...theme.typography.subMenuCaption, mt: 0.2 }} display='block' gutterBottom>
              <ClientTitle showSiteCode showBuildingShortName showSystem />
            </Typography>
          )
        }
      />
    </Box>
  );
};

export default Logo;
