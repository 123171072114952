import { useEffect, useState, useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { FieldAttributes, FieldType } from '../components/fields';
import { Contact, Shift, ShiftQuestion, ShiftType } from '../entities';
import useAxios from 'hooks/useAxios';
import logger from 'utils/logger';
import { BaseForm } from './BaseForm';
import useAppContext from 'hooks/useAppContext';

const InitialState: Shift = {
  date: '',
  type: undefined,
  managerID: 0,
  name: '',
  id: 0,
  activityReport: '',
  openConcernsAndOrViolations: '',
  additionalPersonnelID: 0,
  relievedByID: 0,
  answers: [],
};

interface ShiftFormProps {
  entity?: Shift;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<Shift>;
}

export const ShiftForm = ({ disabled, entity, onCancel, onSubmit }: ShiftFormProps) => {
  const { get } = useAxios();
  const { context } = useAppContext();

  const [loading, setLoading] = useState(false);

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [questions, setQuestions] = useState<ShiftQuestion[]>();

  const methods = useForm<Shift>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const formSchema: FieldAttributes[] = [
    {
      label: 'Manager',
      name: 'manager',
      type: FieldType.SOLOSELECT,
      data: contacts,
      control: methods.control,
    },
    {
      label: 'Aditional Personnel',
      name: 'additionalPersonnel',
      type: FieldType.SOLOSELECT,
      data: contacts,
      control: methods.control,
    },
    {
      label: 'Date',
      name: 'date',
      type: FieldType.DATE,
      control: methods.control,
    },
    {
      label: 'Shift',
      name: 'type',
      type: FieldType.SOLOSELECT,
      data: Object.keys(ShiftType)
        .filter((t) => isNaN(parseInt(t)))
        .map((t, i) => {
          return { name: t, id: i };
        }),
      control: methods.control,
    },
    {
      label: '',
      name: 'answers',
      type: FieldType.LIST,
      data: '/api/shift/questions',
      control: methods.control,
      fullWidth: true,
    },
    {
      label: 'ActivityReport',
      name: 'activityReport',
      type: FieldType.TEXT,
      multiline: true,
      control: methods.control,
    },
    {
      label: 'Open Concerns / Violations',
      name: 'openConcernsAndOrViolations',
      type: FieldType.TEXT,
      multiline: true,
      control: methods.control,
    },
    {
      label: 'Relieved by',
      name: 'relievedBy',
      type: FieldType.SOLOSELECT,
      data: contacts,
      control: methods.control,
    },
  ];

  useEffect(() => {
    get<Contact[]>(`/api/contact/${context?.clientId}/all`).then(setContacts);
    get<ShiftQuestion[]>(`/api/shift/questions`).then(setQuestions);
  }, []);

  useEffect(() => {
    logger.log(entity);
    methods.reset(entity);
  }, [entity]);

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: Shift) => onSubmit(d)} />;
};
