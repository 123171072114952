import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DateFieldAttributes } from '.';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker as MuiDateField } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

export const DateField: React.FC<DateFieldAttributes> = ({ label, name, type, disabled, control, required, format }) => {
  const { register, clearErrors, setValue } = useFormContext();

  return (
    <section>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field, fieldState }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MuiDateField
              {...field}
              {...register(name)}
              label={label}
              value={field.value ? dayjs(field.value) : null}
              //disablePast={true}
              onChange={(newValue) => {
                if (newValue) {
                  setValue(name, newValue?.toISOString());
                  clearErrors(name);
                }
              }}
              disabled={disabled}
              sx={{ ml: 1 }}
              slotProps={{ textField: { size: 'small', required: required, fullWidth: true } }}
              onError={() => 'This field is mandatory'}
            />
          </LocalizationProvider>
        )}
      />
    </section>
  );
};
