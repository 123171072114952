import { LocationOn } from '@mui/icons-material';
import { IconButton, Link, Stack } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender, GridColDef } from '@mui/x-data-grid';
import { InspectionResultAction } from 'components/actions/InspectionResultAction';
import { DataGrid } from 'components/dataGrid/DataGrid';
import { MarkerType } from 'components/maps/base/MapMarker';
import { InspectionResult } from 'entities';
import { InspectionResultVM } from 'entities/viewModels';
import { ReactNode, useEffect, useState } from 'react';
import { InspectionResultType } from 'views/Inspection';
import { Link as RouterLink } from 'react-router-dom';
import useNotification from 'hooks/useNotification';
import useMaps from 'hooks/useMaps';
import BroadcastHelper from 'helpers/BroadcastHelper';

type InspectionResultsTableProps = {
  type: MarkerType;
  inspectionId: number;
  onPass: (item: InspectionResult) => Promise<void>;
  onFail: (item: InspectionResult, type: InspectionResultType) => Promise<void>;
  onLocationClick: (address?: string) => void;
};

export const InspectionResultsTable = ({ type, inspectionId, onFail, onPass, onLocationClick }: InspectionResultsTableProps) => {
  const { warning } = useNotification();
  const [reload, setReload] = useState(false);
  const { inspectionResultHandler } = BroadcastHelper();
  const [dataUrl, setDataUrl] = useState<string>(`/api/inspectionresult/query/${inspectionId}/${type}`);

  const { hasMapItem, moveToItem } = useMaps();

  const RenderActionCell = (params: GridRenderCellParams<InspectionResult, any, any, GridTreeNodeWithRender>, type: InspectionResultType): ReactNode => {
    return <InspectionResultAction item={params.row} type={type} onFail={onFail} onPass={onPass} />;
  };

  const RenderMapActionCell = (params: GridRenderCellParams<InspectionResultVM, any, any, GridTreeNodeWithRender>): ReactNode => {
    if (params.row.device?.address === null) return <></>;

    return (
      <Stack direction='row' height={'100%'} alignItems='center'>
        <IconButton size='small' title='Show on map' color='inherit' onClick={() => onLocationClick(params.row.device?.address)}>
          <LocationOn />
        </IconButton>
      </Stack>
    );
  };

  const columns: GridColDef<InspectionResultVM>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 60,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: RenderMapActionCell,
    },
    {
      field: 'outcomeFunctional',
      headerName: 'Functional',
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return RenderActionCell(params, 'Functional');
      },
    },
    {
      field: 'outcomeVisibility',
      headerName: 'Visibility',
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return RenderActionCell(params, 'Visibility');
      },
    },
    {
      field: 'device.Description',
      headerName: 'Description',
      width: 300,
      filterable: false,
      renderCell(params) {
        const description = params.row.device?.description ?? '';
        return <div title={description}>{description}</div>;
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 200,
      filterable: false,
      renderCell(params) {
        return params.value ? new Date(params.value).toLocaleString() : '';
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      filterable: false,
      renderCell(params) {
        return <div title={params.row.device?.type?.name ?? ''}>{params.row.device?.type?.name ?? ''}</div>;
      },
    },
    {
      field: 'device.Address',
      headerName: 'Address',
      width: 150,
      filterable: false,
      renderCell(params) {
        return params.row.device ? (
          <Link component={RouterLink} to={`/devices/${params.row.deviceID}`}>
            {params.row.device?.address ?? ''}
          </Link>
        ) : (
          <></>
        );
      },
    },
    {
      field: 'signals',
      headerName: 'Signals',
      width: 400,
      filterable: false,
      renderCell(params) {
        const signals =
          params.row.signals
            ?.map((s) => s.type?.name ?? '')
            .filter((t) => t.length > 0)
            .join(', ') ?? '';
        return <div title={signals}>{signals}</div>;
      },
    },
    // {
    //   field: 'location',
    //   headerName: 'Location',
    //   width: 500,
    //   filterable: false,
    //   renderCell(params) {
    //     return params.row.device?.location?.name ?? '';
    //   },
    // },
  ];

  useEffect(() => {
    setDataUrl(`/api/inspectionresult/query/${inspectionId}/${type}`);
  }, [type, inspectionId]);

  useEffect(() => {
    inspectionResultHandler(async (inspectionResult: InspectionResult) => {
      if (inspectionResult.inspectionID === inspectionId) setReload(!reload);
    });
  }, []);

  return <DataGrid id={'inspectionResultsTable'} reload={reload} dataUrl={dataUrl} orderBy='date desc' columnDefinitions={columns} />;
};
