import { ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// third-party
import { FormattedMessage } from 'react-intl';

// project imports
import useAppContext from 'hooks/useAppContext';
import MainCard from 'components/cards/MainCard';
import Transitions from 'components/extended/Transitions';
// import UserImage from 'assets/images/';

// assets
import { IconLogout, IconSettings } from '@tabler/icons-react';
import useConfig from 'hooks/useConfig';

// types
import { ThemeMode } from 'types/theme';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { contact, logout } = useAppContext();
  const { mode, borderRadius } = useConfig();

  const [sdm, setSdm] = useState(true);
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef<any>(null);

  // const getClientByBuilding = () => user?.contact?.clients;
  // const getSiteByBuilding = () => user?.contact?.clients;

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          ml: 2,
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: mode === ThemeMode.DARK ? 'dark.main' : 'primary.light',
          bgcolor: mode === ThemeMode.DARK ? 'dark.main' : 'primary.light',
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: 'primary.main',
            bgcolor: `${theme.palette.primary.main} !important`,
            color: 'primary.light',
            '& svg': {
              stroke: theme.palette.primary.light,
            },
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            alt='user-images'
            sx={{
              ...theme.typography.mediumAvatar,
              margin: '8px 0 8px 8px !important',
              cursor: 'pointer',
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            color='inherit'
          >
            {/* {state.auth.user?.userName?.substring(0, 1).toUpperCase()} */}
          </Avatar>
        }
        label={<IconSettings stroke={1.5} size='24px' />}
        variant='outlined'
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        color='primary'
        aria-label='user-account'
      />

      <Popper
        placement='bottom'
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 14],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions in={open} {...TransitionProps}>
              <Paper>
                {open && (
                  <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                    <Box sx={{ p: 2, pb: 0 }}>
                      <Stack>
                        <Stack direction='row' spacing={0.5} alignItems='center'>
                          <Typography variant='h4'>Good Day,</Typography>
                          <Typography component='span' variant='h4' sx={{ fontWeight: 400 }}>
                            {contact?.name}
                          </Typography>
                        </Stack>
                        {contact?.title && <Typography variant='subtitle2'>{contact?.title}</Typography>}
                        <Typography variant='subtitle2'>Role: {contact?.role}</Typography>
                      </Stack>
                    </Box>
                    {/* <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}> */}
                    <Box sx={{ p: 2, pt: 0 }}>
                      <List
                        component='nav'
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          borderRadius: `${borderRadius}px`,
                          '& .MuiListItemButton-root': { mt: 0.5 },
                        }}
                      >
                        <Divider sx={{ marginTop: 2 }} />
                        <ListItemButton sx={{ borderRadius: `${borderRadius}px` }} selected={selectedIndex === 4} onClick={handleLogout}>
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size='20px' />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant='body2'>
                                <FormattedMessage id='logout' />
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                    {/* </PerfectScrollbar> */}
                  </MainCard>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
