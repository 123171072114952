import { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Contact, ContactGroup } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';
import useAppContext from 'hooks/useAppContext';

const InitialState: ContactGroup = {
  name: '',
  id: 0,
  contacts: [],
  pulseRelays: [],
};

type ContactGroupFormProps = {
  entity?: ContactGroup;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<ContactGroup>;
};

export const ContactGroupForm = ({ disabled, entity, onCancel, onSubmit }: ContactGroupFormProps) => {
  const { context } = useAppContext();

  const methods = useForm<ContactGroup>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const formSchema: FieldAttributes[] = [
    {
      label: 'Name',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
    },
    {
      label: 'Pulse Relay(s)',
      name: 'pulseRelays',
      type: FieldType.MULTISELECT,
      control: methods.control,
      data: `/api/pulseRelay/query`,
    },
    {
      label: 'Contact(s)',
      name: 'contacts',
      type: FieldType.MULTISELECT,
      control: methods.control,
      selectAll: true,
      data: `/api/contact/${context?.clientId}/all`,
      span: 12,
      render(value) {
        const contact: Contact = value as Contact;
        return contact ? `${contact.name} [${contact.email.length > 0 ? contact.email : 'no email'}]` : value.name;
      },
    },
  ];

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: ContactGroup) => onSubmit(d)} />;
};
