import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Device, InspectionResult, MapItem, SignalState } from 'entities';
import { Box, Chip, Collapse, Divider, styled } from '@mui/material';
import { useState } from 'react';
import { InspectionResultAction } from 'components/actions/InspectionResultAction';
import { InspectionResultType } from 'views/Inspection';
import useInspection from 'hooks/useInspection';
import { MapLabel } from 'entities/MapLabel';
import { SignalStateVM } from 'entities/viewModels';

type MapItemCardProps = {
  item: MapItem;
  label?: MapLabel;
  device?: Device;
  bgColor: string;
  signal?: SignalStateVM;
  result?: InspectionResult;
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

export default function MapItemPopup({ item, label, device, bgColor, signal, result }: MapItemCardProps) {
  const [expanded, setExpanded] = useState(false);
  const { handleInspectionResultSubmit, setDrawerData } = useInspection();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (!device && !label)
    return (
      <Card sx={{ maxHeight: 400, p: 0 }}>
        <CardHeader
          sx={{ p: 0, mb: 1 }}
          avatar={
            <Avatar sx={{ width: 50, height: 50 }} aria-label='recipe'>
              <img src={`/icons/notfound.svg`} style={{ width: 'auto', height: 45 }} alt={item.name} />
            </Avatar>
          }
          title={
            <Typography gutterBottom variant='h3' component='div'>
              {item.name}
            </Typography>
          }
          subheader={
            <Box display='flex' flexDirection='column'>
              {item.deviceId && (
                <Typography variant='h5' sx={{ color: 'text.secondary', margin: 0 }}>
                  {`DeviceID: ${item.deviceId}`}
                </Typography>
              )}
              <Typography variant='h5' sx={{ color: 'text.secondary', margin: 0 }}>
                LAT: {item.lat} LNG: {item.lng}
              </Typography>
            </Box>
          }
        />
        <CardContent sx={{ p: 0, m: 0 }}>
          <Typography component='div' sx={{ color: 'text.secondary', margin: 0, fontWeight: 900 }}>
            GENERATED BY Beacon Suite
          </Typography>
        </CardContent>
      </Card>
    );

  if (label) {
    return (
      <Card sx={{ maxHeight: 400, p: 0 }}>
        <CardHeader
          sx={{ p: 0, mb: 1 }}
          avatar={
            <Avatar sx={{ width: 50, height: 50 }} aria-label='recipe'>
              <img src={`/icons/labels/${label?.iconName}`} style={{ width: 'auto', height: 45 }} alt={label?.name} />
            </Avatar>
          }
          title={
            <Typography gutterBottom variant='h3' component='div'>
              {label?.name}
            </Typography>
          }
        />
        <CardContent sx={{ p: 0, m: 0 }}>
          <Typography component='div' sx={{ color: 'text.secondary', margin: 0, fontWeight: 900 }}>
            {label?.description}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ maxHeight: 400, p: 0 }}>
      <CardHeader
        sx={{ p: 0, mb: 1 }}
        avatar={
          <Avatar sx={{ width: 55, height: 55 }} aria-label='recipe'>
            <img
              src={device?.type?.iconName ? `/icons/devices/${device?.type?.iconName}` : '/icons/notfound.svg'}
              style={{ backgroundColor: bgColor, width: 55, height: 55 }}
              alt={device?.name}
            />
          </Avatar>
        }
        // action={
        //   <IconButton aria-label='settings'>
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={
          <Typography gutterBottom variant='h3' component='div'>
            {device?.type?.name ?? device?.name}
          </Typography>
        }
        subheader={
          <>
            {device?.address && (
              <Box display='fled' flexDirection='row'>
                <Typography variant='h5' sx={{ color: 'text.secondary', margin: 0, display: 'flex', flexDirection: 'row', gap: 1 }}>
                  {`Address:`}
                </Typography>
                <Typography variant='h5' sx={{ color: 'text.secondary', margin: 0, fontWeight: 900 }}>
                  {device?.address ?? '-'}
                </Typography>
              </Box>
            )}

            {device?.hardwareType && (
              <Typography variant='subtitle1' sx={{ color: 'text.secondary', margin: 0, fontStyle: 'italic' }}>{`Hardware type: ${device?.hardwareType}`}</Typography>
            )}
            {device?.zone && (
              <Typography variant='subtitle1' sx={{ color: 'text.secondary', margin: 0, fontStyle: 'italic' }}>
                {device?.zone ? `Zone: ${device?.zone}` : ''}
              </Typography>
            )}
          </>
        }
      />
      <CardContent sx={{ p: 0, m: 0 }}>
        <Typography component='div' sx={{ color: 'text.secondary', margin: 0, fontWeight: 900 }}>
          {device?.description}
        </Typography>
        {signal && (
          <Typography
            variant='subtitle1'
            sx={{ color: 'text.secondary', margin: 0, fontStyle: 'italic' }}
          >{`Status: ${signal?.signalActivated.type?.name ?? 'Normal'}`}</Typography>
        )}
        <Typography component='div' sx={{ color: 'text.secondary', margin: 0 }}>
          {device?.name ?? ''}
        </Typography>
        {result && (
          <Box sx={{ mt: 1 }}>
            <Divider />
            <Box sx={{ mt: 1 }} display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
              <Typography component='div' sx={{ color: 'text.secondary', margin: 0 }}>
                Inspection:
              </Typography>
              <Chip
                label={
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
                    <Typography component='div' sx={{ color: 'text.secondary', margin: 0 }}>
                      F:
                    </Typography>
                    <InspectionResultAction
                      item={result}
                      type={'Functional'}
                      onPass={handleInspectionResultSubmit}
                      onFail={async () => await setDrawerData({ type: 'Functional', result: result })}
                    />
                  </Box>
                }
                title='Functional'
              />
              <Chip
                label={
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
                    <Typography component='div' sx={{ color: 'text.secondary', margin: 0 }}>
                      V:
                    </Typography>
                    <InspectionResultAction
                      item={result}
                      type={'Visibility'}
                      onPass={handleInspectionResultSubmit}
                      onFail={async () => await setDrawerData({ type: 'Visibility', result: result })}
                    />
                  </Box>
                }
                title='Visibility'
              />
            </Box>
          </Box>
        )}
      </CardContent>
      {((device?.directions?.trim().length ?? 0) > 0 || (device?.notes?.trim().length ?? 0) > 0) && (
        <>
          <CardActions disableSpacing sx={{ padding: '0px 5px', m: 0 }}>
            <Typography component='div' sx={{ color: 'text.secondary', margin: 0 }}>
              Details:
            </Typography>
            <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label='show detailss'>
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <CardContent sx={{ p: 0, m: 0 }}>
              {device?.directions && (
                <Typography component='div' sx={{ color: 'text.secondary', margin: '0 0 1 0' }}>
                  {device?.directions ?? ''}
                </Typography>
              )}
              {device?.notes && (
                <Typography component='div' sx={{ color: 'text.secondary', margin: 0 }}>
                  {device?.notes ?? ''}
                </Typography>
              )}
            </CardContent>
          </Collapse>
        </>
      )}
    </Card>
  );
}
