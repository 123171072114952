import { styled } from '@mui/material';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { ContactGroup } from 'entities';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

interface ContactGroupsTableActions extends DataGridActionProps<ContactGroup> {}

export default function ContactGroupsTable({ actions }: ContactGroupsTableActions) {
  const columns: GridColDef<ContactGroup>[] = [
    {
      field: 'name',
      headerName: 'Name',
      filterable: false,
      width: 300,
    },
    {
      field: 'contacts',
      headerName: 'Contacts',
      filterable: false,
      width: 500,
      renderCell(params) {
        const contacts = (
          <React.Fragment>
            <li>{params.row?.contacts?.map((p, k) => <ul key={k}>{p.name}</ul>)}</li>
          </React.Fragment>
        );

        return (
          <HtmlTooltip title={contacts}>
            <>{`${params.row?.contacts?.length ?? 0} contacts selected`}</>
          </HtmlTooltip>
        );
      },
    },
    {
      field: 'pulseRelays',
      headerName: 'Pulse Relays',
      filterable: false,
      width: 500,
      renderCell(params) {
        return params.row?.pulseRelays?.map((p) => p.name).join(', ') ?? '';
      },
    },
  ];

  return <DataGrid id={'contactGroupsTable'} dataUrl='/api/contactGroup/query' orderBy={'name asc'} columnDefinitions={actions ? [...actions, ...columns] : columns} />;
}
