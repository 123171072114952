import { useRef, useState } from 'react';

import alarm from '../assets/sounds/alarm.mp3';
import alarmTest from '../assets/sounds/alarm-test.mp3';

export const useAudio = () => {
  const [active, setActive] = useState(false);
  const audio = useRef(new Audio(alarm));

  const play = (test?: boolean) => {
    if (test) {
      audio.current = new Audio(alarmTest);
    }
    audio.current.loop = true;
    audio.current.play();
    setActive(true);
  };

  const silence = () => {
    setActive(false);
    audio.current.pause();
  };

  return { play, silence, active };
};
