import { MapType } from 'components/maps/MapContainer';
import { LayersMap } from 'contexts/MapContext';
import { MapItem, MapLayers } from 'entities';
import { MapItemVM } from 'entities/viewModels';
import { LatLngBounds, Map, Point, SVGOverlay } from 'leaflet';
import { RefObject } from 'react';

const getPoint = (child: DOMRect): Point => {
  const x = Math.floor(child.x - 302.5); // + child.width / 2);
  const y = Math.floor(child.y - 266); // + child.height / 2);
  return new Point(x, y, false);
};

const nts = (value?: number) => value?.toLocaleString(undefined, { maximumFractionDigits: 4 }) ?? 'N/A';

const inRange = (p1: Point, p2: Point, mapId: number, isGate: boolean): boolean => {
  let offsetX = [1, 2, 7].includes(mapId) ? 3 : 1;
  let offsetY = [1, 2, 7].includes(mapId) ? 6 : 3;

  if (isGate) {
    console.log('GATE ITEM');
    offsetX = 30;
    offsetY = 30;
  }

  const x1 = Math.abs(p1.x);
  const y1 = Math.abs(p1.y);
  const x2 = Math.abs(p2.x);
  const y2 = Math.abs(p2.y);
  return (x1 > x2 ? x1 - x2 : x2 - x1) <= offsetX && (y1 > y2 ? y1 - y2 : y2 - y1) <= offsetY;
};

const hiddenClassName = 'svg-layer-hidden';
export const showLayer = (overlayRef: RefObject<SVGOverlay>, layers: LayersMap, mapType: MapType): boolean => {
  let hasDevicesLayer = false;
  try {
    const _layers = Object.keys(layers).map((key) => ({ tag: key, enabled: layers[key] }));
    var elements = overlayRef.current?.getElement()?.getElementsByTagName('g') ?? [];
    for (let index = 0; index < elements.length; index++) {
      const element = elements[index];
      const id = element.getAttribute('id');
      if (id) {
        const _layer = _layers.find((l) => id?.toLocaleLowerCase().includes(l.tag.toLocaleLowerCase()));
        if (_layer) {
          // set layer hiddden and remove other classes
          try {
            element.classList.forEach((v) => element.classList.remove(v));
          } catch (e) {
            console.error('Failed to iterate', e);
          }
          if (!_layer.enabled) element.classList.add(hiddenClassName);
        }

        // always show devices layer for T8 FAS
        if (mapType === 'edit' && id.indexOf('bs-devices') !== -1) {
          element.classList.remove(hiddenClassName);
          hasDevicesLayer = true;
        }
      }
    }
  } catch (e) {
    console.log('Failed to showLayer', e);
  }
  return hasDevicesLayer;
};
var isNumber = /^\d+\.?\d*$/;
export const isDeviceLabel = (label: string) => label.trim().length === 8 && isNumber.test(label.trim());

export const renderLayerItems = async (overlayRef: RefObject<SVGOverlay>, lmap: Map, layer: MapLayers, mapId: number): Promise<MapItem[]> => {
  const items: MapItemVM[] = [];
  const tags = layer.layers.map((l) => l.tagPrefix);

  console.log('LAYER', layer);

  var groups = overlayRef.current?.getPane()?.getElementsByTagName('g') ?? [];
  for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
    const group = groups[groupIndex];

    const id = group.getAttribute('id');
    if (!id) continue;

    const tag = tags.find((tag) => id.indexOf(tag) !== -1);
    if (!tag) continue;

    console.log('RENDERING %s with tag:', id, tag);

    var labels = group.getElementsByTagName('text') ?? [];
    for (let labelIndex = 0; labelIndex < labels.length; labelIndex++) {
      const label = labels[labelIndex];
      // if (element.innerHTML.trim().length < 3) continue; // skip small words
      if (label.innerHTML.indexOf(',') !== -1 && isDeviceLabel(label.innerHTML.split(',')[1])) continue; // skip SW devices
      var rect = label.getBoundingClientRect();
      var itemPoint = getPoint(rect);
      var itemLatLng = lmap.layerPointToLatLng(itemPoint);
      const name = label.firstElementChild?.innerHTML.trim() ?? label.innerHTML.trim();

      const prevItem = items[items.length - 1];

      if (!isDeviceLabel(name) && prevItem && prevItem.x && prevItem.y && inRange(new Point(prevItem.x, prevItem.y, false), itemPoint, mapId, prevItem.name === 'GATE')) {
        if (prevItem.name.indexOf(name.trim()) === -1) {
          // console.log(`COMBINING: ${prevItem.name} + ${name}`);
          prevItem.name += ` ${name}`;
          prevItem.lat = itemLatLng.lat;
          prevItem.lng = itemLatLng.lng;
          prevItem.x = itemPoint.x;
          prevItem.y = itemPoint.y;
        }
      } else {
        items.push({
          name: `${tag === 'grid' ? 'Area ' : ''}${name}`.trim(),
          lat: itemLatLng.lat,
          lng: itemLatLng.lng,
          x: itemPoint.x,
          y: itemPoint.y,
          mapID: mapId,
          id: groupIndex * 10000 + labelIndex,
          // label: {
          //   id: 20000 + items.length,
          //   name: `DOOR ${name}`,
          //   iconName: '007-missing.png',
          // },
        });
      }
    }
  }

  return items;
};

const setAttribute = (element: SVGElement, name: string, value: string) => {
  const attr = element.attributes.getNamedItem(name);

  // if(!attr){
  //   // todo create
  // }
  // attr.value = value;

  if (attr) attr.value = value;
};

export const renderDevicesLayer = (mapId: number, lmap: Map, items: MapItemVM[]) => {
  var minX = 0;
  var minY = 0;
  var maxX = 0;
  var maxY = 0;
  const offset = 100;

  var width = 0;
  var height = 0;

  const element = document.getElementById('bs-devices-layer') as unknown as SVGElement;
  if (!element) return;

  const addDeviceElement = (item: MapItemVM, tag: SVGElement, first: boolean) => {
    const point = lmap.latLngToContainerPoint({ lat: item.lat, lng: item.lng });

    minX = first ? point.x - offset : Math.min(...[minX, point.x - offset]);
    minY = first ? point.y - offset : Math.min(...[minY, point.y - offset]);
    maxX = first ? point.x + offset : Math.max(...[maxX, point.x + offset]);
    maxY = first ? point.y + offset : Math.max(...[maxY, point.y + offset]);

    width = width > (point.x ?? 0) ? width : point.x;
    height = height > (point.y ?? 0) ? height : point.y;

    tag.innerHTML = item.device?.address?.trim() ?? '';
    setAttribute(tag, 'transform', `matrix(1 -0.65 1 1 ${point.x} ${point.y})`);
  };

  const getDeviceTag = (first: boolean): SVGElement => {
    if (first) return devicesTag.getElementsByTagName('text')[0] as SVGElement;
    var deviceTag = devicesTag.getElementsByTagName('text')[0].cloneNode(true) as SVGElement;
    devicesTag.appendChild(deviceTag);
    return deviceTag;
  };

  const devicesTag = element.getElementsByTagName('g')[1];
  const borderTag = element.getElementsByTagName('rect')[0];

  items.forEach((item, index) => addDeviceElement(item, getDeviceTag(index === 0), index === 0));

  var width = maxX - minX + 10;
  var height = maxY - minY + 10;

  setAttribute(element, 'viewBox', `${minX} ${minY} ${width} ${height}`);
  setAttribute(borderTag, 'x', minX.toString());
  setAttribute(borderTag, 'y', minY.toString());
  setAttribute(borderTag, 'width', width.toString());
  setAttribute(borderTag, 'height', height.toString());

  // console.log(element);
};
