import { ReactNode, useEffect, useState } from 'react';
import { Container, Drawer, IconButton, Box, Grid2 as Grid, Collapse, Switch, FormControlLabel, Button, ButtonGroup, Typography } from '@mui/material';
import { useLoaderData } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import MainCard from 'components/cards/MainCard';
import { gridSpacing } from 'config';
import SubCard from 'components/cards/SubCard';
import { InspectionForm } from 'forms/InspectionForm';
import { InspectionResult } from 'entities';
import { InspectionResultForm } from 'forms/InspectionResultForm';
import TableChartIcon from '@mui/icons-material/TableChart';
import MapIcon from '@mui/icons-material/Map';
import useConfirm from 'hooks/useConfirm';
import { MapContainer } from 'components/maps/MapContainer';
import { MarkerType } from 'components/maps/base/MapMarker';
import useInspection from 'hooks/useInspection';
import { InspectionState } from 'entities/enums/InspectionState';
import { MapSearch } from 'components/maps/components/MapSearch';
import { Toggler } from 'components/shared/Toggler';
import { MapSelect } from 'components/maps/components/MapSelect';
import useNotification from 'hooks/useNotification';
import { useWindowSize } from 'hooks/useWindowSize';
import { Spinner } from 'components/shared/Spinner';
import { LoadingButton } from '@mui/lab';
import useMaps from 'hooks/useMaps';
import useSignals from 'hooks/useSignals';
import { InspectionResultsTable } from 'collections/InspectionResultsTable';

export type InspectionResultType = 'Functional' | 'Visibility';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

export const InspectionView = () => {
  const { width } = useWindowSize();
  const params = useLoaderData() as string;
  const { confirm, ConfirmDialog } = useConfirm();

  const { signal } = useSignals();
  const { moveToItem, hasMapItem } = useMaps();
  const { type, inspection, drawerData, setDrawerData, setMarkerType, handleState, getInspection, handleInspectionSubmit, handleInspectionResultSubmit } = useInspection();

  const [loading, setLoading] = useState(true);

  const [id, setId] = useState<number>();
  const [isMobile, setIsMobile] = useState(false);

  const [name, setName] = useState('');

  const [showDetails, setShowDetails] = useState(false);

  // const [show, setShow] = useState<MarkerType>('Uninspected');

  const [component, setComponent] = useState('table');

  const [stateLoader, setStateLoader] = useState(false);
  const [completeLoader, setCompleteLoader] = useState(false);

  const renderActions = (): ReactNode => {
    const handleComplete = async () => {
      if (inspection) {
        setCompleteLoader(true);
        const result = await confirm('Are you sure you want to complete this inspection');
        if (result) handleInspectionSubmit({ ...inspection!, state: InspectionState.Completed, endDate: new Date(Date.now()).toISOString() });
        setCompleteLoader(false);
      }
    };

    if (inspection?.state === InspectionState.Completed) return <></>;

    return (
      id && (
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          <LoadingButton
            loading={stateLoader}
            variant='contained'
            onClick={() => {
              setStateLoader(true);
              handleState(id).then(() => setStateLoader(false));
            }}
          >
            {inspection?.state === InspectionState.InProgress ? 'PAUSE' : 'RESUME'}
          </LoadingButton>
          <LoadingButton loading={completeLoader} variant='outlined' onClick={handleComplete}>
            {'MARK COMPLETE'}
          </LoadingButton>
        </Box>
      )
    );
  };

  const renderForm = (): ReactNode => {
    return (
      <Collapse in={isMobile ? true : showDetails} collapsedSize={72}>
        <SubCard
          title={'Inspection Details'}
          secondary={
            !isMobile && <FormControlLabel control={<Switch checked={showDetails} onChange={(e) => setShowDetails(e.target.checked)} />} label={showDetails ? 'Hide' : 'Show'} />
          }
        >
          {isMobile && <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>{renderActions()}</Box>}
          <InspectionForm readOnly={inspection !== undefined} entity={inspection} />
        </SubCard>
      </Collapse>
    );
  };

  const renderViews = (): ReactNode => {
    if (!id) return <></>;

    return component === 'table' ? (
      <InspectionResultsTable inspectionId={id} type={type} onFail={handleFailed} onPass={handleInspectionResultSubmit} onLocationClick={showOnMap} />
    ) : (
      <MapContainer mapType='inspection' height={'65vh'} />
    );
  };

  const renderContent = (): ReactNode => {
    if (id === 0) return <></>;
    return isMobile ? (
      <>
        <Grid container spacing={gridSpacing}>
          <Grid size={{ xs: 12 }}>{component === 'map' && <MapSelect />}</Grid>
          <Grid size={{ xs: 12 }}>{component === 'map' && <MapSearch />}</Grid>
          <Grid size={{ xs: 9 }}>
            {' '}
            <ButtonGroup variant='outlined'>
              <Button variant={type === 'Uninspected' ? 'contained' : 'outlined'} onClick={() => setMarkerType('Uninspected')}>
                Pending
              </Button>
              <Button variant={type === 'Inspected' ? 'contained' : 'outlined'} onClick={() => setMarkerType('Inspected')}>
                Completed
              </Button>
              <Button variant={type === 'All' ? 'contained' : 'outlined'} onClick={() => setMarkerType('All')}>
                All
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid size={{ xs: 3 }} justifyContent={'flex-end'} flexGrow={1} display={'flex'} gap={1}>
            <Toggler
              id='inspection-view'
              value={component}
              values={[
                { name: 'table', icon: <TableChartIcon /> },
                { name: 'map', icon: <MapIcon /> },
              ]}
              onChange={setComponent}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>{renderViews()}</Grid>
        </Grid>
      </>
    ) : (
      <Grid container spacing={gridSpacing} mb={2}>
        <Grid size={{ xs: 4 }} mt={2}>
          <ButtonGroup variant='outlined'>
            <Button variant={type === 'Uninspected' ? 'contained' : 'outlined'} onClick={() => setMarkerType('Uninspected')}>
              Pending
            </Button>
            <Button variant={type === 'Inspected' ? 'contained' : 'outlined'} onClick={() => setMarkerType('Inspected')}>
              Completed
            </Button>
            <Button variant={type === 'All' ? 'contained' : 'outlined'} onClick={() => setMarkerType('All')}>
              All
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid size={{ xs: 8 }} mt={2} justifyContent={'flex-end'} flexGrow={1} display={'flex'} gap={1}>
          {component === 'map' && (
            <>
              <MapSearch width={250} />
              <MapSelect width={250} />
            </>
          )}
          <Toggler
            id='inspection-view'
            value={component}
            values={[
              { name: 'table', icon: <TableChartIcon /> },
              { name: 'map', icon: <MapIcon /> },
            ]}
            onChange={setComponent}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>{renderViews()}</Grid>
      </Grid>
    );
  };

  const handleFailed = async (item: InspectionResult, type: InspectionResultType) => {
    await setDrawerData({ result: item, type });
  };

  const showOnMap = (address?: string) => {
    if (address)
      hasMapItem(address).then((item) => {
        if (item) {
          setComponent('map');
          moveToItem(item);
        }
      });
  };

  useEffect(() => {
    if (id === 0) {
      setShowDetails(true);
      setLoading(false);
    } else getInspection(id);
  }, [id]);

  useEffect(() => {
    if (width) setIsMobile(width < 450);
  }, [width]);

  useEffect(() => {
    if (params && !isNaN(parseInt(params))) setId(parseInt(params));
    setLoading(false);
  }, [params]);

  useEffect(() => {
    if (inspection) {
      setName(inspection.name);
    }
  }, [inspection]);

  useEffect(() => {
    showOnMap(signal?.address);
  }, [signal]);

  return (
    <>
      <ConfirmDialog />
      {isMobile ? (
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <Typography variant='h5'>{inspection ? `${name} [ ${InspectionState[inspection?.state ?? 0].toUpperCase()} ]` : 'New Inspection'}</Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>{renderContent()}</Grid>
          <Grid size={{ xs: 12 }}>{renderForm()}</Grid>
        </Grid>
      ) : (
        <MainCard
          title={inspection ? `${name} [ ${InspectionState[inspection?.state ?? 0].toUpperCase()} ]` : 'New Inspection'}
          secondary={renderActions()}
          contentSX={{ display: 'flex', flexDirection: 'column' }}
        >
          {renderForm()}
          {renderContent()}
        </MainCard>
      )}
      <Drawer anchor={'right'} open={drawerData !== undefined} onClose={() => setDrawerData(undefined)}>
        <Box
          component='div'
          sx={{
            padding: 2,
            minWidth: 300,
            maxWidth: 420,
            overflow: 'hidden',
          }}
        >
          <Container sx={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton onClick={() => setDrawerData(undefined)}>
              <CancelIcon />
            </IconButton>
          </Container>
          <InspectionResultForm data={drawerData} editMode={false} />
        </Box>
      </Drawer>
    </>
  );
};
