import { useEffect, useRef, useState } from 'react';
import useSignals from 'hooks/useSignals';
import { FeatureGroup, useMap } from 'react-leaflet';
import logger from 'utils/logger';
import { MapMarker } from './MapMarker';
import { InspectionResult, Map, MapItem } from 'entities';
import { MapType } from '../MapContainer';
import useInspection from 'hooks/useInspection';
import { MapItemVM } from 'entities/viewModels';
import useMaps from 'hooks/useMaps';

type MapMarkerLayerProps = {
  map?: Map;
  mapType: MapType;
  zoom: number;
  layerItems?: MapItem[];
};

export const MapMarkerLayer = ({ map, mapType, zoom, layerItems }: MapMarkerLayerProps) => {
  const lmap = useMap();
  const { states } = useSignals();
  const { inspection, type } = useInspection();

  // needed for edit view
  const { mapItems, refreshMap } = useMaps();
  const markersRef = useRef(null); //<MarkerLayer>(null);

  const [iconSize, setIconSize] = useState(5);
  const [items, setItems] = useState<MapItemVM[]>();
  const [results, setResults] = useState<InspectionResult[]>();

  useEffect(() => {
    if (mapType === 'inspection') {
      const _inspectionResults =
        type === 'All'
          ? inspection?.results
          : type === 'Uninspected'
            ? inspection?.results?.filter((r) => r.outcomeFunctional === null || r.outcomeVisibility === null)
            : inspection?.results?.filter((r) => r.outcomeFunctional !== null && r.outcomeVisibility !== null);
      setResults(_inspectionResults);
    }
  }, [mapType, inspection, type]);

  useEffect(() => {
    const size = (zoom <= 3 ? 1 : zoom <= 5 ? 2 : zoom <= 6 ? 3 : zoom <= 7 ? 5 : 10) * zoom;
    logger.log(`ZOOM:${zoom} ICONSIZE: ${size}px`);
    setIconSize(size);
  }, [zoom, map]);

  useEffect(() => {
    if (map) refreshMap();
  }, [map, states]);

  useEffect(() => {
    if (map && mapItems) setItems(mapItems);
  }, [mapItems]);

  useEffect(() => {
    if (mapType !== 'edit') return;
    if (layerItems && layerItems.length > 0)
      setItems(layerItems); //[...(items ?? []), ...layerItems]);
    // else if (!devicesLayerV1.includes(map?.id ?? 0)) get<MapItemVM[]>(`/api/mapitem/${context?.systemId}/${map?.id}/items/v1`).then(setItems);
    else setItems([]);
  }, [layerItems]);

  return (
    <FeatureGroup ref={markersRef}>
      {items?.map((m) => (
        <MapMarker
          item={m}
          key={m.id}
          zoom={zoom}
          mapType={mapType}
          size={iconSize}
          result={results?.find((r) => r.deviceID === m.deviceId)}
          signal={states?.find((r) => r.signalActivated?.deviceID === m.deviceId)}
        />
      ))}
    </FeatureGroup>
  );
};
