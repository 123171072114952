import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Controller, useFormContext } from 'react-hook-form';
import { RadioFieldAttributes } from '.';

export const RadioField: React.FC<RadioFieldAttributes> = ({ label, name, control, required }) => {
  const { register } = useFormContext();

  return (
    <section>
      <Controller
        name={name}
        control={control}
        rules={{ required: required }}
        render={({ field }) => (
          <FormControl
            {...field}
            {...register(name)}
            size='small'
            sx={{ ml: 1, display: 'flex', flexDirection: 'row', gap: 1, justifyContent: 'flex-start', alignItems: 'center' }}
          >
            <FormLabel id='radio-label'>{label}</FormLabel>
            <RadioGroup row aria-labelledby='radio-label' name='radio-group'>
              <FormControlLabel value={true} control={<Radio />} label='Yes' />
              <FormControlLabel value={false} control={<Radio />} label='No' />
            </RadioGroup>
          </FormControl>
        )}
      />
    </section>
  );
};
