import { Box, Button, Grid2 as Grid } from '@mui/material';
import MainCard from 'components/cards/MainCard';
import { ClientTitle } from 'components/shared/ClientTitle';
import AddIcon from '@mui/icons-material/Add';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import DevicesTable from 'collections/DevicesTable';
import { Device } from 'entities';
import { useEffect, useState } from 'react';
import useAxios from 'hooks/useAxios';
import { DeviceForm } from 'forms/DeviceForm';
import Loader from 'components/shared/Loader';
import { SubmitHandler } from 'react-hook-form';
import logger from 'utils/logger';
import SplitButton from 'components/shared/SplitButton';
import { ImportExport } from '@mui/icons-material';
import { exportAs } from '@mui/x-data-grid/internals';
import useAppContext from 'hooks/useAppContext';
import { AppRoles, DataType } from 'entities/enums';
import useNotification from 'hooks/useNotification';
import { GridColDef } from '@mui/x-data-grid';
import { EditTableCellAction } from 'components/actions/EditTableCellAction';
import { inRole } from 'utils/extensions';

export const loader = ({ params }: any) => params?.id?.toString() ?? '';

export const DevicesView = () => {
  const { get, post, getFile } = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const { context, contact } = useAppContext();
  const params = useLoaderData() as string;
  const { success, error } = useNotification();

  const [id, setId] = useState<number>();
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [entity, setEntity] = useState<Device>();

  const isAuthorized = inRole(contact?.role, [AppRoles.Admin, AppRoles.Inspector]);

  const actions: GridColDef<Device>[] = [
    {
      field: 'actions',
      headerName: '',
      width: 70,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell(params) {
        return EditTableCellAction(params, (id) => setId(id), [AppRoles.Admin, AppRoles.Inspector]);
      },
    },
  ];

  const [exportLoading, setExportLoading] = useState(false);

  const handleSubmit: SubmitHandler<Device> = (data) => {
    logger.log('Device SUBMIT', data);
    post<Device>(`/api/Device`, data).then((ok) => {
      if (ok) {
        success(`Added device with address: ${data.address}`);
        setEntity(undefined);
        setShowForm(false);
      } else error(`Failed to add device: ${data.address}`);
    });
    //setShowForm(false);
  };

  const handleDataClick = (type: string) => {
    setExportLoading(true);
    if (type === 'Export') {
      getFile(`/api/data/${context?.clientId}/download/${DataType.Devices}`).then((b) => {
        exportAs(b, 'csv', `aa_devices_${new Date().getTime()}`);
        setExportLoading(false);
      });
    } else {
      // TODO show filedialog
    }
  };

  useEffect(() => {
    if (id && id > 0) {
      get<Device>(`/api/device/${id}`).then((e) => {
        setEntity(e);
        setShowForm(true);
      });
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    // methods.reset(InitialState);
    if (!isNaN(parseInt(params))) setId(parseInt(params));
  }, [params]);

  useEffect(() => {
    if (location.state) {
      setEntity({
        address: location.state?.address,
        description: location.state?.name,
        name: location.state?.name?.toString().replaceAll(' ', '_') ?? '',
        systemID: context?.systemId,
        id: 0,
      });
      setShowForm(true);
    }
  }, [location]);

  if (loading) return <Loader />;

  return (
    <MainCard
      title={showForm ? entity ? `${id === 0 ? 'Add' : 'Edit'} Device: ${entity.name}` : 'New Device' : <ClientTitle prefix={'Devices for'} showBuildingShortName showSystem />}
      secondary={
        !showForm && (
          <Grid container sx={{ width: '100%' }} spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box display='flex' justifyContent='flex-end'>
                <SplitButton loading={exportLoading} options={['Export', 'Import']} variant='outlined' startIcon={<ImportExport />} onClick={handleDataClick} />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }} justifyContent='flex-end'>
              <Box display='flex' justifyContent='flex-end'>
                <Button
                  variant='outlined'
                  startIcon={<AddIcon />}
                  onClick={() => {
                    navigate('/devices/0');
                    setShowForm(true);
                  }}
                >
                  Add Device
                </Button>
              </Box>
            </Grid>
          </Grid>
        )
      }
    >
      {showForm ? (
        <DeviceForm
          entity={entity}
          onSubmit={handleSubmit}
          disabled={!isAuthorized}
          onCancel={() => {
            setEntity(undefined);
            setShowForm(false);
          }}
        />
      ) : (
        <DevicesTable actions={isAuthorized ? actions : []} />
      )}
    </MainCard>
  );
};
