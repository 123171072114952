import { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { PulseRelay } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';
import useAppContext from 'hooks/useAppContext';

const InitialState: PulseRelay = {
  enabled: false,
  contactGroups: [],
  signalTypes: [],
  buildings: [],
  name: '',
  id: 0,
};

type PulseRelayFormProps = {
  entity?: PulseRelay;
  disabled?: boolean;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<PulseRelay>;
};

export const PulseRelayForm = ({ disabled, entity, onCancel, onSubmit }: PulseRelayFormProps) => {
  const { context } = useAppContext();

  const methods = useForm<PulseRelay>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const formSchema: FieldAttributes[] = [
    {
      label: 'Name',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
      required: true,
      span: 5,
    },
    {
      label: 'Enabled',
      name: 'enabled',
      type: FieldType.CHECKBOX,
      control: methods.control,
      span: 1,
    },
    {
      label: 'Buildings',
      name: 'buildings',
      type: FieldType.LOOKUP,
      control: methods.control,
      required: true,
      dataUrl: '/api/building/query',
      span: 6,
    },
    {
      label: 'Contact groups',
      name: 'contactGroups',
      type: FieldType.MULTISELECT,
      control: methods.control,
      required: true,
      data: '/api/contactGroup/query',
    },
    {
      label: 'Signal types',
      name: 'signalTypes',
      type: FieldType.MULTISELECT,
      control: methods.control,
      required: true,
      data: `/api/signalType/query/${context?.systemId}`,
    },
  ];

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: PulseRelay) => onSubmit(d)} />;
};
