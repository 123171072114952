import { Box, Button, Grid2 as Grid } from '@mui/material';
import MainCard from 'components/cards/MainCard';
import { gridSpacing } from 'config';
import useAppContext from 'hooks/useAppContext';
import { useEffect, useState } from 'react';
import SignalsTable from 'collections/SignalsTable';
import EventItemsTable from 'collections/EventItemsTable';
import { AppRoles, SystemState } from 'entities/enums';
import useConfirm from 'hooks/useConfirm';
import useSignals from 'hooks/useSignals';
import { Toggler } from 'components/shared/Toggler';
import { SystemSelector } from 'components/shared/SystemSelector';
import InspectionsTable from 'collections/InspectionsTable';
import { inRole } from 'utils/extensions';
import { ClientTitle } from 'components/shared/ClientTitle';
import { MapContainer } from 'components/maps/MapContainer';
import { MapSearch } from 'components/maps/components/MapSearch';
import { MapSelect } from 'components/maps/components/MapSelect';
import { useWindowSize } from 'hooks/useWindowSize';

const Dashboard = () => {
  const { width } = useWindowSize();
  const { context, contact } = useAppContext();
  const { confirm, ConfirmDialog } = useConfirm();
  const { states, resetSignalStates } = useSignals();

  const [show, setShow] = useState('Active');
  const [systemId, setSystemId] = useState<number>();

  const isMapViewer = inRole(contact?.role, [AppRoles.MapViewer]);
  const hasMultipleSystems = (contact?.systems?.length ?? 0) > 1;

  const handleSignalReset = async () => {
    if (context?.systemState !== SystemState.Normal) return;
    if ((await confirm('Are you sure you want to reset all active states?')) && systemId) await resetSignalStates(systemId);
  };

  useEffect(() => {
    if (context?.systemId) {
      setSystemId(context?.systemId);
    }
  }, [context]);

  return (
    <Grid spacing={gridSpacing} container>
      <Grid size={12}>
        {!isMapViewer && hasMultipleSystems && (
          <MainCard title={'Beacon Suite Systems'}>
            <SystemSelector isMapViewer={isMapViewer ?? false} />
          </MainCard>
        )}
      </Grid>
      {isMapViewer ? (
        <Grid size={{ xs: 12 }}>
          {width!! <= 430 ? (
            <Box gap={2} flexDirection={'column'} display={'flex'}>
              <MapSearch />
              {!hasMultipleSystems && <MapSelect />}
              <MapContainer mapType='alarm' />
              {hasMultipleSystems && (
                <>
                  <MapSelect />
                  <SystemSelector isMapViewer={isMapViewer ?? false} />
                </>
              )}
            </Box>
          ) : (
            <MainCard title={<ClientTitle prefix={'Maps for'} showBuildingShortName showSystem />} secondary={<MapSearch />}>
              {/* <Box> */}
              <Box display={'flex'} justifyContent={'flex-end'} mb={1} gap={2}>
                {hasMultipleSystems && <SystemSelector width={500} isMapViewer={isMapViewer ?? false} />}
                <MapSelect width={250} />
                {/* <DateTimeRangePicker disabled /> */}
              </Box>
              <MapContainer mapType='view' height={'70vh'} />
            </MainCard>
          )}
        </Grid>
      ) : (
        <>
          <Grid size={{ xs: 12, md: 8 }}>
            <MainCard
              title={<ClientTitle prefix={`${show} Signals for`} showSiteCode showBuildingShortName showSystem />}
              secondary={
                !inRole(contact?.role, [AppRoles.SystemViewer, AppRoles.MapViewer]) && (
                  <Grid container>
                    <Grid size={{ xs: 12, md: 12 }}>
                      {show === 'Active' && (
                        <Button
                          sx={{ mr: 1 }}
                          disabled={(context?.systemState ?? SystemState.Normal) !== SystemState.Normal || (states?.length ?? 0) === 0}
                          color='warning'
                          variant='contained'
                          onClick={handleSignalReset}
                        >
                          Reset
                        </Button>
                      )}
                      <Toggler id='dashboard-state' values={[{ name: 'Active' }, { name: 'All' }]} value={show} onChange={setShow} />
                    </Grid>
                  </Grid>
                )
              }
            >
              <ConfirmDialog />
              <SignalsTable showActions={true} type={show === 'Active' ? 'Active' : 'All'} />
            </MainCard>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Box gap={1} display={'flex'} flexDirection={'column'}>
              <MainCard title={'Event Logs'}>
                <EventItemsTable pageSizeOptions={[5]} />
              </MainCard>
              {inRole(contact?.role, [AppRoles.Inspector]) && (
                <MainCard title={'Inspections'}>
                  <InspectionsTable pageSizeOptions={[5]} limited={true} />
                </MainCard>
              )}
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Dashboard;
