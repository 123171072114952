// src/components/Field.tsx
import React, { ReactNode } from 'react';
import { Control, FieldPath } from 'react-hook-form';
import { InputField } from './InputField';
import { SelectField, SelectOption } from './SelectField';
import { LookupField } from './LookupField';
import { IntervalField } from './IntervalField';
import { DatagridField } from './DatagridField';
import { DateField } from './DateField';
import { AutoCompleteField } from './AutoCompleteField';
import { MultiSelectField } from './MultiSelectField';
import { RadioField } from './RadioField';
import { SoloSelectField } from './SoloSelectField';
import { ListField } from './ListField';
import { NamedEntity } from 'entities/base/Entity';
import { CheckboxField } from './CheckboxField';

enum FieldType {
  TEXT = 'text',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  LOOKUP = 'lookup',
  INTERVAL = 'interval',
  LIST = 'list',
  DATAGRID = 'DATAGRID',
  DATE = 'date',
  DATETIME = 'dateTime',
  AUTOCOMPLETE = 'autocomplete',
  MULTISELECT = 'multiselect',
  RADIO = 'radio',
  SOLOSELECT = 'soloselect',
}

interface BaseFieldAttributes {
  label: string;
  type: FieldType;
  name: FieldPath<any>;
  control: Control<any, any>;
  hidden?: boolean; // TODO implement in overrides
  required?: boolean;
  disabled?: boolean;
  description?: string; // helper text on control

  //validation?: Omit<RegisterOptions<TFieldValues, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;

  render?: (value: NamedEntity) => string;

  // grid props
  span?: number;
  fullWidth?: boolean;
}

interface SelectFieldAttributes extends BaseFieldAttributes {
  type: FieldType.SELECT;
  options: SelectOption[];
}

interface ListFieldAttributes extends BaseFieldAttributes {
  type: FieldType.LIST;
  data: string | NamedEntity[];
}

interface SoloSelectFieldAttributes extends BaseFieldAttributes {
  type: FieldType.SOLOSELECT;
  data: NamedEntity[];
}

interface AutoCompleteFieldAttributes extends BaseFieldAttributes {
  type: FieldType.AUTOCOMPLETE;
  dataUrl: string;
}

interface LookupFieldAttributes extends BaseFieldAttributes {
  type: FieldType.LOOKUP;
  dataUrl: string;
  nonLazy?: boolean;
}

interface MultiSelectFieldAttributes extends BaseFieldAttributes {
  type: FieldType.MULTISELECT;
  limitTags?: number;
  selectAll?: boolean;
  data: string | NamedEntity[];
}

interface InputFieldAttributes extends BaseFieldAttributes {
  type: FieldType.TEXT;
  inputType?: 'text' | 'password' | 'email' | 'tel';
  multiline?: boolean;
}

interface IntervalFieldAttributes extends BaseFieldAttributes {
  type: FieldType.INTERVAL;
  select: FieldPath<any>;
}

interface DatagridFieldAttributes extends BaseFieldAttributes {
  type: FieldType.DATAGRID;
  dataUrl: string;
  dataIdField: string;
}

interface DateFieldAttributes extends BaseFieldAttributes {
  type: FieldType.DATE;
  format?: string;
}

interface RadioFieldAttributes extends BaseFieldAttributes {
  type: FieldType.RADIO;
}

interface CheckboxFieldAttributes extends BaseFieldAttributes {
  type: FieldType.CHECKBOX;
}

export type FieldAttributes =
  | SelectFieldAttributes
  | SoloSelectFieldAttributes
  | LookupFieldAttributes
  | InputFieldAttributes
  | IntervalFieldAttributes
  | DatagridFieldAttributes
  | DateFieldAttributes
  | AutoCompleteFieldAttributes
  | MultiSelectFieldAttributes
  | RadioFieldAttributes
  | ListFieldAttributes
  | CheckboxFieldAttributes;

export {
  FieldType,
  type SelectFieldAttributes,
  type SoloSelectFieldAttributes,
  type LookupFieldAttributes,
  type InputFieldAttributes,
  type IntervalFieldAttributes,
  type DatagridFieldAttributes,
  type DateFieldAttributes,
  type AutoCompleteFieldAttributes,
  type MultiSelectFieldAttributes,
  type RadioFieldAttributes,
  type ListFieldAttributes,
  type CheckboxFieldAttributes,
};

export const Field: React.FC<FieldAttributes> = (props) => {
  switch (props.type) {
    case FieldType.TEXT:
      return <InputField {...props} />;
    case FieldType.SELECT:
      return <SelectField {...props} />;
    case FieldType.SOLOSELECT:
      return <SoloSelectField {...props} />;
    case FieldType.LOOKUP:
      return <LookupField {...props} />;
    case FieldType.INTERVAL:
      return <IntervalField {...props} />;
    case FieldType.DATAGRID:
      return <DatagridField {...props} />;
    case FieldType.DATE:
      return <DateField {...props} />;
    case FieldType.AUTOCOMPLETE:
      return <AutoCompleteField {...props} />;
    case FieldType.MULTISELECT:
      return <MultiSelectField {...props} />;
    case FieldType.RADIO:
      return <RadioField {...props} />;
    case FieldType.LIST:
      return <ListField {...props} />;
    case FieldType.CHECKBOX:
      return <CheckboxField {...props} />;
    default:
      throw new Error('Invalid Field Type');
  }
};
