import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { SystemVM } from 'entities/viewModels';

interface SystemsTableActions extends DataGridActionProps<SystemVM> {}

export default function SystemsTable({ actions }: SystemsTableActions) {
  const columns: GridColDef<SystemVM>[] = [
    {
      field: 'name',
      headerName: 'Name',
      filterable: false,
      width: 300,
    },
    {
      field: 'building',
      headerName: 'Building',
      filterable: false,
      width: 200,
      renderCell(params) {
        return params.row.building?.name;
      },
    },
    {
      field: 'maps',
      headerName: 'Maps',
      filterable: false,
      width: 400,
      renderCell(params) {
        const title = params.row.maps?.map((m) => m.name.replace('Concourse', 'Con'))?.join(', ') ?? '';
        return <div title={title}>{title}</div>;
      },
    },
  ];

  return <DataGrid id={'systemsTable'} dataUrl='/api/system/query' hideHeader={false} orderBy={'name asc'} columnDefinitions={actions ? [...actions, ...columns] : columns} />;
}
