import { MapItem } from 'entities/MapItem';
import { DivIcon, LatLng, Marker as LMarker, Point, PointExpression } from 'leaflet';
import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import { renderToString } from 'react-dom/server';
import { MoveItem } from '../components/contextMenu/menuItems/MoveItem';
import { DeleteItem } from '../components/contextMenu/menuItems/DeleteItem';
import ContextMenu from '../components/contextMenu/ContextMenu';
import useAxios from 'hooks/useAxios';
import useNotification from 'hooks/useNotification';
import useAppContext from 'hooks/useAppContext';
import { MapType } from '../MapContainer';
import { OpenWith } from '@mui/icons-material';
import useConfirm from 'hooks/useConfirm';
import MapItemPopup from './MapItemPopup';
import { InspectionResult, SignalState } from 'entities';
import useMaps from 'hooks/useMaps';
import { ThemeMode } from 'types/theme';
import useConfig from 'hooks/useConfig';
import { MAXZOOM } from '../MapContainer';
import { AppRoles } from 'entities/enums';
import { inRole } from 'utils/extensions';
import { MapItemVM, SignalStateVM } from 'entities/viewModels';
import { isDeviceLabel } from 'helpers/LayerHelper';

type MapMarkerProps = {
  item: MapItemVM;
  size: number;
  zoom: number;
  mapType: MapType;
  signal?: SignalStateVM;
  result?: InspectionResult;
};

export type MarkerType = 'All' | 'Alarm' | 'Active' | 'Inspected' | 'Uninspected';

export function MapMarker({ item, signal, result, size, zoom, mapType }: MapMarkerProps) {
  const lmap = useMap();
  const { contact } = useAppContext();
  const { mode } = useConfig();
  const { put, del } = useAxios();
  const { warning } = useNotification();
  const { confirm, ConfirmDialog } = useConfirm();
  const { map, mapItem, markerType, refreshMap, moveToItem } = useMaps();

  const markerRef = useRef<LMarker>(null);
  const [icon, setIcon] = useState<DivIcon>();

  const [draggable, setDraggable] = useState(false);
  const [position, setPosition] = useState({ lat: item.lat, lng: item.lng });
  //const [position, setPosition] = useState(lmap.containerPointToLatLng(new Point(item.x ?? 0, item.y ?? 0)));
  const [offSetPopup, setOffSetPopup] = useState<PointExpression>([0, 10]);
  const [mouseEvent, setMouseEvent] = useState<{ event: React.MouseEvent; position: LatLng }>();

  // waterflow active (IS: 21), alarm active (ID:19), pull station act (ID: 15)
  const isRedAlarm = () => (signal?.signalTypeID ? [15, 19, 21].includes(signal?.signalTypeID) && signal.isActive : false);
  // building?.signalStates?.find((s) => s.signalActivated?.deviceID === item.deviceId && [15, 19, 21].includes(s.signalActivated?.signalTypeID))?.isActive ?? false;

  // TAMPER ACT (ID:1), SUPERVISORY ACT (ID:26)
  const isOrangeAlarm = () => (signal?.signalTypeID ? [1, 25].includes(signal?.signalTypeID) && signal.isActive : false);
  // building?.signalStates?.find((s) => s.signalActivated?.deviceID === item.deviceId && [1, 26].includes(s.signalActivated?.signalTypeID))?.isActive ?? false;

  // all other if active
  const isYellowAlarm = () => signal?.isActive ?? false;
  //building?.signalStates?.find((s) => s.signalActivated?.deviceID === item.deviceId)?.isActive ?? false;

  const isInspected = () => (result ? result.outcomeFunctional !== null && result.outcomeVisibility !== null : false);
  const isUninspected = () => (result ? result.outcomeFunctional === null || result.outcomeVisibility === null : true);
  const isPartialInspected = () =>
    result ? (result.outcomeFunctional === null && result.outcomeVisibility !== null) || (result.outcomeFunctional !== null && result.outcomeVisibility === null) : false;

  const shouldShow = () => {
    if (markerType === 'Alarm') return isRedAlarm() || isYellowAlarm() || isOrangeAlarm();

    if (mapType === 'inspection') {
      if (!result) return false; // dont show devices without result
      if (markerType === 'Inspected') return isInspected();
      if (markerType === 'Uninspected') return isUninspected();
    }

    return true; // markerType === 'All';
  };

  const getMarkerStyle = (): string => {
    const prefix = 'leaflet-marker-icon leaflet-div-icon leaflet-zoom-animated leaflet-interactive';
    if (mapType !== 'edit') {
      if (isRedAlarm()) return `${prefix} alarmRed pulse`;
      if (isOrangeAlarm()) return `${prefix} alarmOrange pulse`;
      if (isYellowAlarm()) return `${prefix} alarmYellow pulse`;
    }

    if (mapType === 'edit') {
      // show yellow for labels and blue for devices by the generator
      return `${prefix} ${item.deviceId || isDeviceLabel(item.name) ? 'marker' : 'uninspected'}`;
    }

    return zoom >= MAXZOOM - 3 ? `${prefix} marker` : prefix;
  };

  const getBackgroundColor = () => {
    return mapType !== 'inspection'
      ? isRedAlarm()
        ? 'rgba(255, 0, 0, 0.5)'
        : isOrangeAlarm()
          ? 'rgba(255, 139, 23, 0.6)'
          : isYellowAlarm()
            ? 'rgba(255, 255, 0, 0.5)'
            : '#dddddd'
      : isInspected()
        ? 'green'
        : isPartialInspected()
          ? 'pink'
          : 'lightgrey';
  };

  const handleEditItem = () => {};

  const handleDragItem = () => {
    toggleDraggable();
    setMouseEvent(undefined);
  };

  const handleDeleteItem = () => {
    // setMouseEvent(undefined);
    const itemName = item.device?.address ?? item.label?.name;
    // TODO enable confirmation
    // confirm(`Are you sure to delete item ${itemName}`).then((ok) => {
    //   if (ok) {
    del(`/api/mapItem/${item.id}`).then(() => {
      refreshMap();
      warning(`Sucessfully deleted map item ${itemName}`);
      setMouseEvent(undefined);
    });
    // }
    // });
  };

  useEffect(() => {
    if (mapItem?.id === item.id) {
      const marker = markerRef.current;
      if (marker !== null) {
        // if (!mapItem) {
        marker.openPopup();
        moveToItem(undefined);
        // lmap.flyTo([item.lat, item.lng], 7);
        // lmap.invalidateSize(true);
        // }
      }
    }
  }, [markerRef.current, mapItem]);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null && item.id) {
          const newCoordinates = marker.getLatLng();
          // const oldCoordinates = lmap.latLngToLayerPoint([item.lat, item.lng]);
          // const newPoint = lmap.latLngToLayerPoint(newCoordinates);
          setPosition(newCoordinates);
          setDraggable(false);
          marker.closePopup();
          const _item: MapItem = {
            ...item,
            lat: newCoordinates.lat,
            lng: newCoordinates.lng,
            // x: newPoint.x,
            // y: newPoint.y,
          };
          console.log('UPDATED MAPITEM', _item);
          put<MapItem>(`/api/mapItem/${item.id}`, _item).then(() => {
            refreshMap();
            marker.closePopup();
          });
        }
      },
      dblclick(e: any) {
        if (mapType === 'edit' && item.deviceId) handleDragItem();
      },
      // popupclose(e: any) {
      //   if (mapItem) moveToItem(undefined);
      // },
      contextmenu(e: any) {
        setMouseEvent({ event: e.originalEvent, position: e.position });
      },
    }),
    []
  );

  const toggleDraggable = useCallback(() => {
    setDraggable((d) => !d);
  }, []);

  useEffect(() => {
    const iconName = item.device?.type?.iconName
      ? `/icons/devices/${item.device.type?.iconName}`
      : item.label?.iconName
        ? `/icons/labels/${item.label.iconName}`
        : `/icons/notfound.svg`;

    const bgColor = getBackgroundColor();
    //get<string>(`/api/map/icon`, { params: { name: iconName } }).then((svg) => {
    if (iconName) {
      const iconString = renderToString(
        draggable ? (
          <OpenWith className={getMarkerStyle()} fontSize='large' />
        ) : (
          <img
            className={getMarkerStyle()}
            style={{ backgroundColor: mapType === 'edit' ? 'transparent' : bgColor, width: `${size}px`, height: `${size}px` }}
            src={`${iconName}`}
          />
        )
      );
      setIcon(new DivIcon({ html: iconString, iconSize: [size, size] }));
    }
  }, [item, signal, result, size, draggable]);

  // useEffect(() => {
  //   if (item && item.x && item.y) setPosition(lmap.containerPointToLatLng(new Point(item.x, item.y)));
  //   else setPosition({ lat: item.lat, lng: item.lng });
  // }, [item, item.y, item.x]);

  useEffect(() => {
    if (item) setPosition({ lat: item.lat, lng: item.lng });
  }, [item]);

  return (
    icon &&
    shouldShow() && (
      <>
        <ConfirmDialog />
        <Marker
          draggable={draggable}
          eventHandlers={eventHandlers}
          position={position}
          ref={markerRef}
          icon={icon}
          title={`${item.device?.address ?? ''} ${item.device?.description ?? ''}`}
        >
          <Popup autoClose={true} closeOnClick={!result} closeButton={false} className={mode === ThemeMode.DARK ? 'popup-dark' : ''} offset={offSetPopup} maxHeight={250}>
            <MapItemPopup bgColor={getBackgroundColor()} signal={signal} item={item} result={result} device={item.device} label={item.label} />
          </Popup>
          {mapType !== 'alarm' && (
            <ContextMenu mouseEvent={mouseEvent}>
              {/* <EditItem
              onClick={() => {
                // console.log('edit item:', item);
                setMouseEvent(undefined);
              }}
            /> */}
              {<MoveItem onClick={handleDragItem} isMoving={draggable} />}
              {inRole(contact?.role, [AppRoles.Admin]) && mapType === 'edit' && <DeleteItem onClick={handleDeleteItem} />}
            </ContextMenu>
          )}
        </Marker>
      </>
    )
  );
}
