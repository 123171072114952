import { useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Pulse } from 'entities';
import { FieldAttributes, FieldType } from 'components/fields';
import { BaseForm } from './BaseForm';
import useAppContext from 'hooks/useAppContext';

const InitialState: Pulse = {
  date: '',
  message: '',
  contactGroups: [],
  name: '',
  id: 0,
};

type PulseFormProps = {
  entity?: Pulse;
  disabled?: boolean;
  submitLabel?: string;
  onCancel: VoidFunction;
  onSubmit: SubmitHandler<Pulse>;
};

export const PulseForm = ({ disabled, entity, onCancel, onSubmit }: PulseFormProps) => {
  const { context } = useAppContext();

  const methods = useForm<Pulse>({
    defaultValues: useMemo(() => {
      return entity ?? InitialState;
    }, [entity]),
  });

  const formSchema: FieldAttributes[] = [
    {
      label: 'Subject',
      name: 'name',
      type: FieldType.TEXT,
      control: methods.control,
      required: true,
    },
    {
      label: 'Contact group(s)',
      name: 'contactGroups',
      type: FieldType.MULTISELECT,
      control: methods.control,
      //required: true,
      data: '/api/contactGroup/query',
    },
    {
      label: 'Message',
      name: 'message',
      type: FieldType.TEXT,
      multiline: true,
      control: methods.control,
      required: true,
      span: 12,
    },
  ];

  return <BaseForm methods={methods} readOnly={disabled} formSchema={formSchema} onCancel={onCancel} onSubmit={(d: Pulse) => onSubmit(d)} />;
};
