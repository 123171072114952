import { GridColDef } from '@mui/x-data-grid';
import { DataGrid, DataGridActionProps } from 'components/dataGrid/DataGrid';
import { EventItem } from 'entities';

interface EventItemsTableActions extends DataGridActionProps<EventItem> {}

interface EventItemTableProps extends EventItemsTableActions {
  pageSizeOptions?: number[];
}

export default function EventItemsTable({ actions, pageSizeOptions }: EventItemTableProps) {
  const columns: GridColDef<EventItem>[] = [
    {
      field: 'date',
      headerName: 'Date',
      filterable: false,
      width: 200,
      renderCell(params) {
        return <>{new Date(params.value).toLocaleString()}</>;
      },
    },
    {
      field: 'name',
      headerName: 'Incident',
      filterable: false,
      width: 300,
    },
  ];

  return (
    <DataGrid id={'eventItemsTable'} hideHeader={true} orderBy={'date desc'} pageSizeOptions={pageSizeOptions} columnDefinitions={actions ? [...actions, ...columns] : columns} />
  );
}
